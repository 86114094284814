<template>
  <div>
    <Modal>
      <form @submit.prevent="agregar_catalogo">
        <div class="title">{{ id === null ? 'Nuevo catálogo' : 'Editar catálogo' }}</div>
        <div class="body">
          <div class="row form-group">
            <label for="catalogo.catalogo" class="col-form-label col-sm-2">Nombre</label>
            <div class="col-sm-10"><input v-model="catalogo.catalogo" type="text" name="catalogo.catalogo" id="catalogo.catalogo" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="catalogo.codigo" class="col-form-label col-sm-2">Código</label>
            <div class="col-sm-10">
              <input v-model="catalogo.codigo" type="text" name="catalogo.codigo" id="catalogo.codigo" class="form-control">
              <small>Código único para identificar el catálogo, sin espacios o caracteres especiales, ejemplo: mi_catalogo</small>
            </div>
          </div>
          <div class="row form-group">
            <label for="catalogo.estatus" class="col-form-label col-sm-2">Estatus</label>
            <div class="col-sm-10"catalogo.estatus>
              <select v-model="catalogo.estatus" name="catalogo.estatus" id="" class="form-control">
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Opciones</label>
            <div class="col-sm-10">
              <div class="row">
                <table class="table table-striped table-valores">
                  <thead>
                    <th>Opción</th>
                    <th>Código</th>
                    <th>valor</th>
                    <th>Eliminar</th>
                  </thead>
                  <tbody>
                    <tr v-for="(valor, index) in catalogo.valores" :key="index">
                      <td>{{ valor.nombre }}</td>
                      <td>{{ valor.codigo }}</td>
                      <td>{{ valor.valor }}</td>
                      <td><button class="btn btn-info" type="button" :name="'valor_'+index" :id="'valor_'+index" @click="eliminar_opcion(index)">Eliminar</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-sm-2 offset-sm-10"><button type="button" class="btn secondary-btn" @click="nueva_opcion=true">Agregar valor</button></div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn">{{id === null ? 'Crear catálogo' : 'Guardar catálogo'}}</button></div>
            <div class="col-sm-2"><button type="button" class="btn danger-btn" @click="cerrar_catalogos">Cancelar</button></div>
          </div>
        </div>
      </form>
    </Modal>

    <Modal v-if="nueva_opcion" :options="{width: '40vw'}">
      <form @submit.prevent="agregar_opcion">
        <div class="title">Nueva </div>
        <div class="body">
          <div class="row form-group">
            <label for="opcion.nombre" class="col-form-label col-sm-3">Nombre</label>
            <div class="col-sm-9"><input v-model="opcion.nombre" type="text" name="opcion.nombre" id="opcion.nombre" class="form-control" autocomplete="off"></div>
          </div>
          <div class="row form-group">
            <label for="opcion.codigo" class="col-form-label col-sm-3">Código</label>
            <div class="col-sm-9">
              <input v-model="opcion.codigo" type="text" name="opcion.codigo" id="opcion.codigo" class="form-control" autocomplete="off">
              <small>El código debe ser único, sin espacios ni caracteres especiales</small>
            </div>
          </div>
          <div class="row form-group">
            <label for="opcion.valor" class="col-form-label col-sm-3">Valor</label>
            <div class="col-sm-9"><input v-model="opcion.valor" type="text" name="opcion.valor" id="opcion.valor" class="form-control" autocomplete="off"></div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn">Agregar opción</button></div>
            <div class="col-sm-3"><button type="button" class="btn danger-btn" @click="cancelar_opcion">Cancelar</button></div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import api from '@/apps/tesoreria/api/catalogos'

  export default {
    components: {
      Modal
    }
    ,props: {
      data: {
        type: Object
        ,default: function() {
          return {
            id: null
            ,catalogo: null
            ,codigo: null
            ,estatus: 1
            ,valores: []  
          }
        }
      }
    }
    ,data: function() {
      return {
        catalogo: {
          catalogo: null
          ,codigo: null
          ,estatus: 1
          ,valores: []
        }
        ,opcion: {
          nombre: null
          ,codigo: null
          ,valor: null
        }
        ,nueva_opcion: false
      }
    }
    ,mounted: function() {
      this.catalogo = this.data;

      this.obtener_catalogo();
    }
    ,methods: {
      obtener_catalogo: async function() {
        try {
          if (this.catalogo.id) {
            this.catalogo = (await api.obtener_catalogo(this.catalogo.id)).data;
          }
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,agregar_opcion: function() {
        let mensajes = [];

        if (!this.opcion.nombre || this.opcion.nombre.length == 0)
          mensajes.push('Debes definir el nombre de la opción');

        let validacion = /[^a-z0-9\_\-]+/;

        if (!this.opcion.codigo || this.opcion.codigo.length == 0)
          mensajes.push('Debes definir el código de la opción');
        // else if (validacion.test(this.opcion.codigo))
        //   mensajes.push('El código no es válido, no debe contener espacios ni caracteres especiales ni letras en mayusculas');

        if (!this.opcion.valor || this.opcion.valor.length == 0)
          mensajes.push('Debes definir el el valor de la opción');

        let encontrado = false;
        this.catalogo.valores.forEach(valor => {
          if (!encontrado && valor.codigo == this.opcion.codigo)
            encontrado = true
        })

        if (encontrado)
          mensajes.push('El valor del código ya fue agregado');


        this.$log.info('mensajes',mensajes);
        if (mensajes.length > 0) {
          let msg = '<ul>';

          mensajes.forEach(tmp => {
            msg += '<li>'+tmp+'</li>';
          })

          msg += '</ul>';

          this.$log.info('msg', msg);
          return this.$helper.showMessage('Error',msg,'error','alert');
        }

        this.catalogo.valores.push(this.opcion);
        this.opcion = {
          nombre: null
          ,codigo: null
          ,valor: null
        }
        this.nueva_opcion = false;

        // try {
        //   let res = (await api.crear_catalogo(this.opcion)).data;
        //   this.$log.info('res',res)
        // }catch(e) {
        //   this.$log.info('Error',e);
        //   this.$helper.showAxiosError(e,'Error');
        // }
      }
      ,cancelar_opcion: function() {
        this.opcion = {
          nombre: null
          ,codigo: null
          ,valor: null
        }
        this.nueva_opcion = false;
      }
      ,eliminar_opcion: function(index) {
        let opciones = [];
       
        for(let i=0; i<this.catalogo.valores.length; i++) {
          if (i != index)
            opciones.push(this.catalogo.valores[i]);
        }

        this.catalogo.valores = opciones;
      }
      ,agregar_catalogo: async function() {
        try {
          let mensajes = [];

          if (!this.catalogo.catalogo || this.catalogo.catalogo == '')
            mensajes.push('Debes definir el nombre del catálogo');

          let validacion = /[^a-z0-9\_\-]+/;

          if (!this.catalogo.codigo || this.catalogo.codigo == '')
            mensajes.push('Debes definir el código del catálogo')
          else if (validacion.test(this.catalogo.codigo))
            mensajes.push('El código no es válido, no debe contener espacios ni caracteres especiales ni letras en mayusculas');

          if (this.catalogo.valores.length == 0)
            mensajes.push('Debes agregar por lo menos una opción');

          if (mensajes.length > 0) {
            let msg = '<ul>';

            mensajes.forEach(tmp =>  {
              msg += '<li>'+tmp+'</li>';
            })

            msg += '</ul>';

            return this.$helper.showMessage('Error', msg, 'error','alert');
          }

          let res = null;
          if (!this.catalogo.id)
            res = (await api.crear_catalogo(this.catalogo)).data;
          else
            res = (await api.actualizar_catalogo(this.catalogo.id,this.catalogo)).data;

          this.$log.info('res',res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_catalogos: function() {
        this.$emit('close');  
      }
    }
  }
</script>

<style lang="scss">
  .table-valores {
    thead {
      background-color: #4D4D4D !important;
      color: #fff;
      
      th {
        padding: 5px 3px !important;
      }
    }
  }

  small {
    color: #B3B3B3;
  }
</style>